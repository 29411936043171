import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "./Navbar"
import { Container } from "react-bootstrap"
import "../assets/scss/app.scss"

const Layout = ({ image, title, children }) => {
  return (
    <div className={"d-flex flex-column gap-5"}>
      <Helmet>
        <title>{title} | Inwestprojekt</title>
      </Helmet>
      <div className={"site-banner"}>
        {image}
        <Navbar />
        <div className={"tlo cover"}>
          <h1 className={"text-banner"}>{title}</h1>
        </div>
      </div>
      <Container className="d-flex flex-column gap-5 mb-5">
        {children}
      </Container>
    </div>
  )
}

export default Layout
