import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"

const ProjektyGotowe = () => {
  return (
    <Layout
      title={"Projekty Gotowe"}
      image={
        <StaticImage
          src={"../assets/images/ready.jpg"}
          alt={"budynek"}
          placeholder={"blurred"}
          className={"site-banner"}
          style={{ position: "absolute" }}
        />
      }
    >
      <div>
        W Biurze prowadzimy sprzedaż projektów gotowych wraz z ewentualną ich
        przeróbką zgodnie z życzeniem Inwestora oraz opracowujemy adaptacje tych
        projektów do warunków miejscowych wynikających z decyzji o warunkach
        zabudowy i zagospodarowania terenu działki lub wypisu i wyrysu z
        miejscowego planu zagospodarowania przestrzennego obejmującego
        przedmiotową działkę.
      </div>
      <div>
        Prowadzimy współpracę z biurami projektowymi, których projekty gotowe
        sprzedajemy w cenach tych Biur. Jednocześnie w ramach zakupu projektu
        gotowego, załatwiamy bezpłatnie zgodę autora projektu na ewentualne
        zmiany w zakupionym projekcie, które mogą być wprowadzone do projektu na
        etapie jego adaptacji.
      </div>
      <div>Projekty gotowe sprowadzamy z następujących biur:</div>
      <div
        className={"d-flex flex-column justify-content-center partners black"}
      >
        <a href={"https://archeton.pl/"} target="_blank" rel="noreferrer">
          F.P. "Archeton" w Krakowie{" "}
        </a>
        <a target="_blank" rel="noreferrer" href={"https://www.archon.pl/"}>
          Biuro Projektów "Archon+" w Myślenicach P.P
        </a>
        <a target="_blank" rel="noreferrer" href={"https://www.archipelag.pl/"}>
          "Archipelag" we Wrocławiu
        </a>
        <a target="_blank" rel="noreferrer" href={"http://www.archigraph.pl/"}>
          "Archigraph" sp. z o.o. w Krakowie
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://www.studioatrium.pl/"}
        >
          Studio "Atrium" w Bielsko - Biała
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://archi-projekt.com.pl/"}
        >
          "Archi - projekt" sp z o.o. w Lublinie
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"http://www.agroprojekty.pl/"}
        >
          "Agrobisp" sp. z o.o. w Krakowie
        </a>
        <a target="_blank" rel="noreferrer" href={"https://lipinscy.pl/"}>
          B.P. "M&L Lipińscy" we Wrocławiu
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://www.domoweklimaty.pl/"}
        >
          A.P.A. "Jarosław Charkiewicz" w Białymstoku
        </a>
        <a target="_blank" rel="noreferrer" href={"https://horyzont.com/"}>
          A.P.P. "Horyzont" we Wrocławiu
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://www.domywstylu.pl/?rdct=mtmstyl"}
        >
          M.T.M. "Styl" sp z o.o. w Białymstoku
        </a>
        <a target="_blank" rel="noreferrer" href={"https://postawdom24.pl/"}>
          J.B.P.i P. Jeleniogórskie Biuro Planowania i Projektowania sp. z o.o.
          w Jeleniej Górze
        </a>
        <a target="_blank" rel="noreferrer" href={"http://bienkowscy.pl/"}>
          A.P. "Bieńkowscy" Chyby koło Poznania
        </a>
        <a target="_blank" rel="noreferrer" href={"https://lk-projekt.pl/"}>
          "LK & Projekt" sp z o.o. w Krakowie
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://www.galeriadomow.pl/"}
        >
          Galeria Domów
        </a>
        <a target="_blank" rel="noreferrer" href={"https://www.kbprojekt.pl/"}>
          "KB Projekt" w Krakowie
        </a>
        <a target="_blank" rel="noreferrer" href={"https://www.dobredomy.pl/"}>
          Pracownia Projektowa "Dobre domy" Flak&Abramowicz we Wrocławiu
        </a>
        <a target="_blank" rel="noreferrer" href={"https://www.domus.wroc.pl/"}>
          Pracownia Projektowa "Domus" Dom u Niechowskich we Wrocławiu
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://www.mgprojekt.com.pl/"}
        >
          MG Projekt Pracownia Architektoniczna w Warszawie
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://studiokrajobrazy.pl/"}
        >
          Autorskie Studio Architektury "Krajobrazy" we Wrocławiu
        </a>
        <a target="_blank" rel="noreferrer" href={"https://bauherr.pl/"}>
          Bauherr S.A. w Krakowie
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://www.dom-projekt.pl/"}
        >
          Dom-Projekt w Myślenicach
        </a>
        <a target="_blank" rel="noreferrer" href={"https://www.arex.net.pl/"}>
          Autorska Pracownia Architektoniczna "Arex" w Warszawie
        </a>
        <a target="_blank" rel="noreferrer" href={"http://projektygotowe.pl/"}>
          Projekty.Gotowe.Pl w Zabłociu
        </a>
        <a target="_blank" rel="noreferrer" href={"https://domna5.pl/"}>
          Dom na piątkę we Wrocławiu
        </a>
        <a target="_blank" rel="noreferrer" href={"https://www.pro-arte.pl/"}>
          "ProARTE" w Żorach
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://www.domdlaciebie.com.pl/"}
        >
          "Archeco Sp. z o.o." Bielany Wrocławskie koło Wrocławia
        </a>
        <a target="_blank" rel="noreferrer" href={"https://z500.pl/"}>
          "Studio Z500" w Warszawie
        </a>
        <a target="_blank" rel="noreferrer" href={"https://www.dominanta.pl/"}>
          "Grupa Projektowa Dominanta" w Białymstoku
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"http://www.inwestprojekt.com.pl/"}
        >
          "SOB Inwestprojekt Sp. z o.o." w Warszawie
        </a>
        <a target="_blank" rel="noreferrer" href={"https://projektyzwizja.pl/"}>
          Pracownia "Domy z Wizją" w Warszawie
        </a>
        <a target="_blank" rel="noreferrer" href={"https://www.archdom.pl/"}>
          ARCH-DOM
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://artinex-projekt.pl/"}
        >
          ARTINEX
        </a>
        <a target="_blank" rel="noreferrer" href={"http://www.bobstudio.eu/"}>
          BOB STUDIO
        </a>
        <a target="_blank" rel="noreferrer" href={"https://domenadom.pl/"}>
          DOMENA
        </a>
      </div>
    </Layout>
  )
}

export default ProjektyGotowe
